import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import initGalleries from './lib/imageGallery'
import $ from 'jquery';
import * as LazyLoad from './lib/lazyload';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";


const routing = (
    <Router>
        <div id="page">
            <Switch>
                <Route path="/" component={App} />
            </Switch>
        </div>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

$(()=> {
    initGalleries();
    new LazyLoad()
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
