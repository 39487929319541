import React from "react";
import "./Tags.css"

function Tags(props) {
    const listItems = props.tags.map((tag, index) =>
        <li key={index}>{tag}</li>
    );
    return (
        <ul className="tags">{listItems}</ul>
    );
}

export default Tags;