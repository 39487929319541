import $ from "jquery";

/**
 * Copyright Florian Reinhold
 */
class ImageGallery {
    constructor(gallery) {
        this.images = $(gallery).find('.js-image');
        this.images.click(this.clickImage.bind(this));
        this.selectedImage = $();
    }

    clickImage(event) {
        const el = $(event.target);

        this.selectedImage.removeClass('selected');
        if(el.is(this.selectedImage)) {
            console.log('unselect');
            this.selectedImage = $();
            return;
        }

        el.addClass('selected');
        this.selectedImage = el;
    }

    scrollTo(el) {
        const offset = el.offset();
        $('html, body').animate({
            scrollTop: offset.top - 20
        });
    }
}

function initGalleries(elements) {

    $(elements).each((i, gallery) => {
        new ImageGallery(gallery);
    });
}

export default initGalleries;