import React, { Component } from 'react';
import Tags from "./Tags";
import "./Header.css";

class Header extends Component {
    render() {
        const tags = <Tags tags={['Curious', 'Different', 'Dreamer']}/>;

        return (
            <header className="page-head">
                <img src="img/profile.png" className="profile" alt="Kemomi Logo"/>
                <div className="headline">
                    <h1>
                        Florian "Kani" Reinhold
                        {tags}
                    </h1>


                    <p>
                        There is more then one Idea in my head... it's exploding with ideas to program or build.
                        Here is a small list of projects I've done or am working on.
                    </p>
                    <div className="more">
                        <a href="https://stackoverflow.com/users/story/1462830">
                            Stackoverflow
                        </a>
                        <a href="https://www.codingame.com/profile/6ea8d9d6d038c8a6a8109db237626c5c185867">
                            Codeingame
                        </a>
                        <a href="https://www.facebook.com/kanimaru">
                            Facebook
                        </a>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
