import React, {Component} from 'react';
import './App.css';
import Header from "./components/Header";
import Projects, {Person} from "./Data";
import {Route} from "react-router";
import Project from "./components/Project";
import Profile from "./components/Profile";
import CV from "./components/CV";
import Contests from "./components/Contest";
import Favorite from "./components/Favorite";

class App extends Component {
    render() {
        let projects = Projects.map(project =>
            <Project key={project.id} project={project}/>
        );

        const application = ()=> <CV data={Person} />;

        return (
            <>
                <Header/>
                <main>
                    <h2 id="about-me">About Me</h2>
                    <section>
                        <Profile data={Person}/>
                        <Favorite data={Person}/>
                        <Route path="/application" render={application} />
                    </section>

                    <h2 id="projects">Projects</h2>
                    <section className="project-list">
                        {projects}
                    </section>
                    <Contests data={Person.contests}/>
                </main>
            </>
        );
    }
}

export default App;
