import React, {Component} from 'react';

import './CV.css'

export default class CV extends Component {

	createCompany(company, id) {
		return (<div key={id} className="workplace">
            <span className="company-name">
				{company.name}
				<span className="seniority">
					<time dateTime={company.from}>{company.from}</time>
					- <time dateTime={company.to}>{company.to}</time>
				</span>
				<span className="position">{company.position}</span>
            </span>
			<ul className="projects">
				{company.projects.map(
					(project, idx) => <li key={idx}>{project}</li>
				)}
			</ul>
		</div>);
	}

	createHobbies(hobby, id) {
		return (
			<li key={id}>{hobby}</li>
		);
	}

	render() {
		const data = this.props.data;
		return (
			<section className="cv">
				<h3>Curriculum Vitae</h3>
				<div className="content">
					<article className="school">
						<h4>School</h4>
						<ul>
							{data.school.map(
								(school, idx) => <li key={idx}>{school}</li>
							)}
						</ul>
					</article>
					<article className="work">
						<h4>Work</h4>
						{data.work.map(this.createCompany)}
					</article>
					<article className="hobbies">
						<h4>Hobbies</h4>
						<ul>
							{data.hobby.map(this.createHobbies)}
						</ul>
					</article>
				</div>
			</section>
		);
	}
}