import React from "react";

const ProjectIds = {
    kemomi: "kemomi",
    voidSword: "voidSword",
    cuttles: "cuttles",
    botlor: "botlor",
    budgeteer1: "budgeteer1",
    spikes: "spikes",
    tamagotchi: "tamagotchi"
};

const Projects = [
    {
        "id": ProjectIds.kemomi,
        "name": "Kemomi",
        "tags": [
            "Life goal",
            "Book",
            "On Hold"
        ],
        "when": "2008-forever",
        "description": (<span>Started in 2008 a friend and I was thinking about an idea of a story.
            We though about a parallel universe similar to ours, that was made of an Test at Cern,
            you know the Large Hadron Collider (LHC). We started to model a whole dimension around this idea
            with a kind of physics, geological properties etc. After then we started to write a story line about it.
            But currently we are stuck in a phase were we have no toolings. For planing and writing this story.
            So this project is currently on hold until we wrote our toolings.</span>),

    },
    {
        "id": ProjectIds.voidSword,
        "name": "Void Sword",
        "tags": [
            "Crafting",
            "LED",
            "Touch Sensor",
            "In progress"
        ],
        "when": "2018-today",
        "description": (<span>Next sword build with same material as
            <a href={"#" + ProjectIds.cuttles}>Cuttles</a> but more finer.
            It will get a touch sensor in the back of the sword, and some led's into the blade.
            It has a 3d printed handle and is one of the hardest projects, I've ever made. Many challenges)</span>),
        "images": [
            "img/voidSword/20170917_032620.jpg",
            "img/voidSword/20170917_151033.jpg",
            "img/voidSword/20170917_202802.jpg",
            "img/voidSword/20170922_020540.jpg",
            "img/voidSword/20171008_152405.jpg",
            "img/voidSword/20180102_165854.jpg",
            "img/voidSword/20180103_192332.jpg",
            "img/voidSword/20180402_154536.jpg"
        ],
        "repository": "https://gitlab.kemomi.de/kani/VoidSword"
    },
    {
        "id": ProjectIds.botlor,
        "name": "Botlor",
        "tags": [
            "Spring-Integration",
            "Java"
        ],
        "when": "2018-2019",
        "description": (<span>I wanted to write a Teamspeak Bot that handles the regular tasks of an admin / moderator.
            And make our Teamspeak Server Unique. You start earning "ActionPoints" with being active and spend
            them to earn rights, channels etc. It also manages the channels, so when you are not active anymore
            the channel starts decaying until the bot deletes it. Also it handles the passwords with a key system,
            so you can write the bot to join a channel, he checks if you have the permissions to join the channel
            and moves you. Never forget the password again :)</span>)
    },
    {
        "id": ProjectIds.budgeteer1,
        "name": "Budgeteer - Software",
        "tags": [
            "ZK", "Spring Boot", "Sass", "Product"
        ],
        "when": "2018-today",
        "description": (<span>Budgeteer is an easy budget managing software.
            It's my first little product built in ZK for my gym
            <a href='https://buffalobox.de/'>Buffalobox</a>. They handled the complete budget for the members on
            Paper, so I decided to write them a Software that handles it.</span>),
        "example": "http://budgeteer.kemomi.de"
    },
    {
        "id": ProjectIds.spikes,
        "name": "Spikes",
        "tags": [
            "Crafting",
            "Lily Pad",
            "Bluetooth LE",
            "In progress"
        ],
        "when": "2018-today",
        "description": "Spikes is a little project that should demonstrate, " +
            "that cloths and bio sensor can work easy together. The spikes should pulse " +
            "with the same rate as the hearth sensor. I want to learn BTLE and Lily Pad in this Project.",
        "images": [
            "img/spike/20180411_210420.jpg",
            "img/spike/20180411_210439.jpg",
            "img/spike/20180412_002337.jpg"
        ]
    },
    {
        "id": ProjectIds.tamagotchi,
        "name": "Tamagotchi Necklace",
        "tags": [
            "Crafting",
            "SSD1331",
            "Arduino",
            "3D Modeling / 3D Print",
            "Stuck"
        ],
        "when": "2018-today",
        "description": "This cute little project should be a necklace, that shows different faces and looks cute. " +
            "I want to learn how to program optimally an SSD1331 on a low memory CPU like ATMega328. " +
            "I stuck with power supply and memory.",
        "video": "media/tamagotchi.webm"
    },
    {
        "id": "my-homepage",
        "name": "This Homepage",
        "tags": [
            "React",
            "Node.js",
            "Sass"
        ],
        "when": "2013-today",
        "description": "The current homepage you are seeing right now. It's currently the 4. revision and a chance to learn React, after the previous versions used hand crafted HTML."
    },

    {
        "id": "fish",
        "name": "Fish",
        "tags": [
            "Crafting",
            "3D Modeling / 3D Print",
            "Air Brush",
            "Done"
        ],
        "when": "2017",
        "description": "First completely self made 3d model to print from begin to end. I used the first picture as reference and used Blender to create the Model. For slicing I used Cura and I printed it on a Anycubic Prusa I3.",
        "images": [
            "img/knoedelfisch/vorlage.jpg",
            "img/knoedelfisch/fish1.jpg",
            "img/knoedelfisch/fish2.jpg",
            "img/knoedelfisch/fish3.jpg",
            "img/knoedelfisch/fish4.jpg",
            "img/knoedelfisch/fish5.jpg"
        ]
    },
    {
        "id": "lightning",
        "name": "2d Light Engine",
        "tags": [
            "Java",
            "GLSL Shader",
            "Prototype",
            "Canceled"
        ],
        "when": "2012",
        "description":
            (<span>Started in 2012 a friend an me wanted to program a little game engine.
                Just cause.... we could &gt;__&lt; , everyone was taking parts of it.
                We stopped this project after a while as I experienced Unity 3d and he lost motivation for it.
                Anyway I learned much about performance in Java and the Shader Language from OpenGL.
                I completely focused on the dynamic correct light in this game.
                Today I would rewrite most of this code, cause it "smells" very bad.</span>),
        "repository": "https://gitlab.kemomi.de/kani/GameEngine",
        "images": [
            "img/lightning/test-1.png",
            "img/lightning/test-2.png",
            "img/lightning/test-3.png",
            "img/lightning/test-4.png",
        ]
    },
    {
        "id": "japan2016",
        "name": "Japan",
        "tags": [
            "Life goal",
            "Photo",
            "Done"
        ],
        "when": "2016",
        "description": "Normally I wouldn't put it into my portfolio. But it was a dream for more then 6 years to goto japan. I did everything to realize this dream comes true and after I was there it was more then awesome. I love this country, the peoples and the mindset! Here are some impressions of it. ",
        "images": [
            "img/japan/japan1.jpg",
            "img/japan/japan2.jpg",
            "img/japan/japan3.jpg",
            "img/japan/japan4.jpg",
            "img/japan/japan5.jpg",
            "img/japan/japan7.jpg",
            "img/japan/japan9.jpg",
            "img/japan/japan10.jpg",
            "img/japan/japan11.jpg",
            "img/japan/japan12.jpg"
        ]
    },
    {
        "id": "minipong",
        "name": "Arduino-Pong",
        "tags": [
            "Arduino",
            "C",
            "Crafting",
            "Done"
        ],
        "when": "2018",
        "description": "A little story to that: As I started to learn programming, I needed some project to do - learning by doing - I was looking for a project that handles most basic aspects of programming. I started to implement Pong in every language I learned. Visual Basic (moved Buttons and Radio boxes), over C++ (with Irrlicht 3d Framework) or on a Microprocessor with oscilloscope as display etc. Pong handles Input, Output, animations, \"file access to the assets\" and simple math logic. Everything needed to learn a new language. And this version was done on an Arduino needed about 2,5h to complete from scratch. A display module (SSD1331) and sound (default PC Beeper) and input via joystick.",
        "video": "media/pong.webm"
    },
    {
        "id": "cuttles",
        "name": "Cuttles",
        "tags": [
            "Crafting",
            "LED",
            "Cosplay",
            "Done"
        ],
        "when": "2016-2017",
        "description": "Cuttles was a \"little\" project to get a first impression with the new materials. It consists of Profoam, Cosplayflex, PVC Pipe, some LEDs and batteries. It take about 100h to make. The clue in this sword are the flasks on the left side. It has an mechanism to change the color of the LEDs when the flasks are changes. That was realized with some resistors built into the flasks. But it is way to heavy and fragile to take it on convention.",
        "images": [
            "img/cuttles/received_1542838482393546.jpeg",
            "img/cuttles/received_1542839095726818.jpeg",
            "img/cuttles/20170526_155331.jpg",
            "img/cuttles/20170526_155359.jpg",
            "img/cuttles/20170604_163134.jpg",
            "img/cuttles/20170604_163326.jpg",
            "img/cuttles/20170604_163359.jpg",
            "img/cuttles/20170613_004212.jpg",
            "img/cuttles/20170613_215332.jpg",
            "img/cuttles/20170615_165409.jpg",
            "img/cuttles/20170615_165427.jpg",
        ]
    },
    {
        "id": "shutter",
        "name": "Shutter",
        "tags": [
            "Painting",
            "Done"
        ],
        "when": "2015",
        "description": "My first paint job on a nerf gun in 2015. Before I got this into my hands it was signal red. Official name of this toy is: Nerf N-Strike Elite Mega Magnus",
        "images": [
            "img/shutter/shutter1.jpg",
            "img/shutter/shutter2.jpg",
            "img/shutter/shutter3.jpg"
        ]
    }
];

const Person = {
    "me": {
        "name": "Florian Reinhold",
        "nickname": "Kani",
        "age": "27",
        "birthdate": "1991-06-03"
    },
    "contact": {
        "email": "ich@florian-reinhold.de",
        "phone": "+49 176/42596542"
    },
    "address": {
        "street": "Stefanienstrasse 1",
        "city": "Waldbronn",
        "postcode": "76337"
    },
    "languages": [
        {"name": "Java", "knowledge": "Native"},
        {"name": "HTML5/CSS3", "knowledge": "Native"},
        {"name": "JS", "knowledge": "Very good"},
        {"name": "Bash", "knowledge": "Good"},
        {"name": "C", "knowledge": "Rusty Good"},
        {"name": "C#", "knowledge": "Rusty Good"},
        {"name": "C++", "knowledge": "Rusty Good"},
        {"name": "Ruby", "knowledge": "Hello World"},
        {"name": "Pyhton", "knowledge": "Hello World"},
    ],
    "os": [
        "Linux (Ubuntu, Raspbian)", "Android"
    ],
    "editor": [
        "Jetbrains Products", "vim"
    ],
    "frameworks": [
        "ZKoss", "Spring Boot", "Spring Cloud",
        "Spring Security", "Spring Integration", "Spring Data",
        "Spring Shell", "Tensorflow", "Unity3D",
        "React", "Bootstrap"
    ],
    "technologies": [
        "Docker", "Gitlab", "Kafka", "Zookeeper", "Elastic Stack", "Kubernetes", "Sonatype Nexus",
        "Apache2", "Tomcat"
    ],
    "databases": [
        "Postgres/gis", "Sqlite", "H2", "HSQL"
    ],
    "knowledge": {
        "os": ["Windows"],
        "editor": ["Eclipse"],
        "frameworks": ["Irrlicht 3D", "too much Java libs to count", "Hazelcast"],
        "technologies": ["Apache2", "Tomcat"],
        "databases": ["Oracle", "MongoDB"],
    },

    "school": ["Grundschule Geschwister Scholl",
        "Sekudarschule Friedrichstadt",
        "Franziska Höll Realschule",
        "Gewerbeschule Bühl",
        "Heinrich Hertz Berufsschule"],
    "work": [
        {
            "name": "Preis Ingenieur Büro",
            "position": "Trainee",
            "from": "2008",
            "to": "2011",
            "projects": [
                "Software for turnstile's on a Atmel micro controller in C",
                "Software for managing inventory and maintenance of custom build sensors"
            ]
        },
        {
            "name": "Mobile Collaboration / Celerocloud",
            "position": "Senior Software Engineer",
            "from": "2011",
            "to": "2019",
            "projects": [
                "Android app for handling / input questionnaires",
                "Ticket management system aka issue board for the core product",
                "integration of an E-Learning solution for the core product",
                "Responsible for personal processes (Germany and Austria) for the core product",
                "Candidate portal from scratch to roll out",
                "Integrating our core product into external solutions via API",
                "Introducing and maintaining Devops theme like: Docker, Gitlab, Kafka"
            ]
        }
    ],
    "hobby": [
        "Tinkering - Small projects with electronic, 3d printing and airbrush",
        "Sport - Skating and Crossfit as counter part to the pc work",
        "Japan - traveling, watching japanese animes, cooking japanese food"
    ],
    "contests": [
        {
            "where": "Codingame",
            "competitions": [
                {
                    "name": "CodeBusters",
                    "img": "/img/contest/codeBuster.jpg",
                    "myRank": "690",
                    "maxRank": "1,979"
                },
                {
                    "name": "Smash the Code",
                    "img": "/img/contest/smashTheCode.jpg",
                    "myRank": "1,335",
                    "maxRank": "2,490"
                },
                {
                    "name": "Coders Strike Back",
                    "img": "/img/contest/codersStrikeBack.jpg",
                    "myRank": "623",
                    "maxRank": "2,530"
                },
                {
                    "name": "Code vs Zombies",
                    "img": "/img/contest/codeZombies.jpg",
                    "myRank": "409",
                    "maxRank": "3,963"
                },
                {
                    "name": "Code a la Mode",
                    "img": "/img/contest/codeAlaMode.jpg",
                    "myRank": "436",
                    "maxRank": "1543"
                }
            ]

        }
    ],

    "primary": [
        "Coding", "Planning", "Optimising"
    ],

    "secondary": [
        "Design", "Inspiration", "Knowledge"
    ],

    "trait": [
        "Over-Engineering"
    ]


};

export {Projects as default, Person};
