import React, { Component } from 'react';
import initGalleries from "../lib/imageGallery";
import $ from 'jquery';
import "./Gallery.css";

class Gallery extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
        initGalleries($('.js-gallery'));
    }

    render() {
        const galleryItems = this.props.images.map((image) =>
            <div key={image} className="image lazyload js-image loader" data-src={image}>&nbsp;</div>
        );
        return (
            <div className="gallery js-gallery">
                {galleryItems}
            </div>
        );
    }
}

export default Gallery;