import React, { Component } from 'react';
import Tags from "./Tags";
import './Profile.css';
import {Route} from "react-router";

class Profile extends Component {

    static toClsName(name) {
        return name.toLowerCase().replace(" ", "-");
    }

    createContact(contact) {
        return <>
            <dt>E-Mail</dt>
            <dd><a href={'mailto:' + contact.email}>{contact.email}</a></dd>
            <dt>Phone</dt>
            <dd><a href={'tel:' + contact.phone}>{contact.phone}</a></dd>
        </>
    }

    createAddress(address) {
        return <address>
            <dl>
                <dt>Street</dt>
                <dd>{address.street}</dd>
                <dt>City</dt>
                <dd>{address.postcode + ' ' + address.city}</dd>
            </dl>
        </address>
    }

    render() {
        let data = this.props.data;

        const languages = data.languages.map(((value, index) =>
            <li key={index} className={Profile.toClsName(value.knowledge)}>
                {value.name}<span>{value.knowledge}</span>
            </li>
        ));

        return (
        <div className="profile">
            <section className="me">
               <dl>
                   <dt>Name:</dt>
                   <dd>{data.me.name}</dd>
                   <dt>Nickname:</dt>
                   <dd>{data.me.nickname}</dd>
                   <dt>Age:</dt>
                   <dd>{data.me.age}</dd>
                   <Route path="/application" render={()=>
                       <>
                           <dt>Birthdate:</dt>
                           <dd>{data.me.birthdate}</dd>
                       </>
                   } />

                   <dt>Primary:</dt>
                   <dd className="tagged"><Tags tags={data.primary} /></dd>
                   <dt>Secondary:</dt>
                   <dd className="tagged"><Tags tags={data.secondary} /></dd>

                   <Route path="/application" render={()=>this.createContact(data.contact)} />
               </dl>
                <Route path="/application" render={()=>this.createAddress(data.address)} />
            </section>

            <section className="languages">
                <h4>Languages I "speak":</h4>
                <ul>{languages}</ul>
            </section>
        </div>);

    }
}

export default Profile;