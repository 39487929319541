import React, {Component} from 'react';

import './Contest.css'

export default class Contests extends Component {

	renderContest(contest, idx) {
		const competitionsElement = contest.competitions.map((competition, idx) =>
			<article style={{backgroundImage: `url(${competition.img})`}} key={idx}>
				<header>
					<h2>{competition.name}</h2>
					<h3 className="rank">
						Rank:
						<span className="my">{competition.myRank}</span>/
						<span className="max">{competition.maxRank}</span>
					</h3>
				</header>
			</article>
		);


		return <div key={idx} className="contest">
			<h4>{contest.where}</h4>
			{competitionsElement}
		</div>
	}

	render() {
		const data = this.props.data;

		return (
			<section className="contests">
				<h3>Contests</h3>
				<div className="content">
				{ data.map((contest, idx) => this.renderContest(contest, idx)) }
				</div>
			</section>
		);
	}
}