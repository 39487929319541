import React, { Component } from 'react';
import Tags from "./Tags";
import Gallery from "./Gallery";
import "./Project.css"

class Project extends Component {
    render() {
        let tags;

        const project = this.props.project;
        if(project.tags !== undefined) {
            tags = <Tags tags={project.tags} />
        }

        let gallery;
        if(project.images !== undefined) {
            gallery = <Gallery images={project.images}/>
        }

        let video;
        if(project.video !== undefined) {
            video = <div style={{"textAlign": "center"}}>
                <video height="300px" controls={true}>
                    <source src={project.video} type="video/webm"/>
                </video>
            </div>
        }

        let more;
        if(project.repository !== undefined) {
            more = <div className="more">
                <a href={project.repository}>
                    Git Repository
                </a>
            </div>
        }

        return (
            <article id={project.id}
                     className="project">
                <div className="image-container">
                    <header>
                        <h2>{project.name}</h2>
                        <h3>{project.when}</h3>
                    </header>
                </div>
                {tags}
                <p>{project.description}</p>
                {gallery}
                {video}
                {more}
            </article>
        );
    }
}

export default Project;