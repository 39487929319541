import React from "react";
import Tags from "./Tags";
import "./Favorite.css"

export default function Favorite(props) {
	return <section className="favorites">
		<h3>Things I love to use:</h3>
		<div className="content">
			<article>
				<h4>Operation Systems</h4>
				<Tags tags={props.data.os}/>
			</article>
			<article>
				<h4>Editor</h4>
				<Tags tags={props.data.editor}/>
			</article>
			<article>
				<h4>Frameworks</h4>
				<Tags tags={props.data.frameworks}/>
			</article>
			<article>
				<h4>Technology</h4>
				<Tags tags={props.data.technologies}/>
			</article>
			<article>
				<h4>Databases</h4>
				<Tags tags={props.data.databases}/>
			</article>
		</div>
	</section>
}